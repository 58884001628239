/* eslint-disable @typescript-eslint/no-explicit-any */
const webhookUrl = process.env.NEXT_PUBLIC_DISCORD_WEBHOOK_URL;

export async function sendToDiscord(payload: any) {
  try {
    if (!webhookUrl) return;
    await fetch(webhookUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
  } catch (error) {
    console.error("Failed to send error to Discord", error);
  }
}
