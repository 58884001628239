var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3W4m0sK5WHlWVpPciGtPg"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs";
import { UAParser } from "ua-parser-js";

import { sendToDiscord } from "./services/discord/send-to-discord";

const regexRules = [/Symbol not found:/i];

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  dsn: process.env.SENTRY_PUBLIC_DSN,

  // Add optional integrations for additional features
  integrations: [Sentry.replayIntegration()],

  // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
  tracesSampleRate: 1,

  // Define how likely Replay events are sampled.
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // Define how likely Replay events are sampled when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  beforeSend: (event, hint) => {
    const error = hint?.originalException;
    if (!(error instanceof Error)) return null;

    const userAgent = navigator?.userAgent;
    const parser = new UAParser(userAgent);
    const uaParseResult = parser.getResult();

    // Send error to Discord
    const errorTitle = error?.name ?? "Error";
    const errorMessage = error?.message ?? "No error message";
    const formatKeyValuePairs = (entries: [string, unknown][]) => {
      return entries
        .filter(([, value]) => value !== undefined)
        .map(([key, value]) => `${key}: ${value}`)
        .join("\n");
    };

    const userAgentContent = Object.entries(uaParseResult)
      .map(([key, value]) => {
        return {
          name:
            key === "ua"
              ? key.toUpperCase()
              : key.charAt(0).toUpperCase() + key.slice(1),
          value:
            key === "ua" ? value : formatKeyValuePairs(Object.entries(value)),
        };
      })
      .filter(({ value }) => value !== undefined);

    const payload = {
      embeds: [
        {
          title: `Client ${errorTitle}`,
          description: errorMessage,
          color: 16711680,
          fields: [
            ...userAgentContent,
            {
              name: "Url",
              value: window.location.href,
            },
          ],
        },
      ],
    };

    sendToDiscord(payload);

    // Ignore errors that match the regex rules
    if (error instanceof Error) {
      for (const rule of regexRules) {
        if (error && error.message && error.message.match(rule)) {
          return null;
        }
      }
    }
    return event;
  },
});
